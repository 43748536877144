// ======================================================================
// Forge Lite | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');



  // Tobi
// ======================================================================

import Tobii from '@midzer/tobii';

var light = document.getElementById('editorial');

if (light) {
const tobii = new Tobii({
  captions: false,
  zoom: false
})
}



 // Video Player
function hepburnPlayer(container) {


  // Select elements here
  const video = container.querySelector('.videosrc');
  const videoControls = container.querySelector('.video-controls');
  const playButton = container.querySelector('.play');
  const playbackIcons = container.querySelectorAll('.playback-icons use');

  const volumeButton = container.querySelector('.volume-button');

  const volumeIcons = container.querySelectorAll('.volume-button use');
  const volumeMute = container.querySelector('use[href="#volume-mute"]');
  const volumeHigh = container.querySelector('use[href="#volume-high"]');

  const videoContainer = container;

  video.controls = false;
  if (videoContainer.classList.contains('videocontrols')) {
    videoControls.classList.remove('hidden');
  }

  // Add functions here

  // togglePlay toggles the playback state of the video.
  // If the video playback is paused or ended, the video is played
  // otherwise, the video is paused
  function togglePlay() {
    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  }

  // updatePlayButton updates the playback icon and tooltip
  // depending on the playback state
  function updatePlayButton() {
    playbackIcons.forEach(icon => icon.classList.toggle('hidden'));

    if (video.paused) {
      playButton.setAttribute('data-title', 'Play (k)')
    } else {
      playButton.setAttribute('data-title', 'Pause (k)')
    }
  }


  // updateVolumeIcon updates the volume icon so that it correctly reflects
  // the volume of the video
  function updateVolumeIcon() {

    volumeIcons.forEach(icon => {
      icon.classList.add('hidden');
    });

    volumeButton.setAttribute('data-title', 'Mute (m)')

    if (video.muted) {
      volumeMute.classList.remove('hidden');
      volumeButton.setAttribute('data-title', 'Unmute (m)')
    } else {
      volumeHigh.classList.remove('hidden');
    }
  }

  updateVolumeIcon();

  // toggleMute mutes or unmutes the video when executed
  // When the video is unmuted, the volume is returned to the value
  // it was set to before the video was muted
  function toggleMute() {
    video.muted = !video.muted;
    if (video.muted) {
      video.muted = true;
    } else {
      video.muted = false;
    }
  }

  // togglePip toggles Picture-in-Picture mode on the video
  async function togglePip() {
    try {
      if (video !== document.pictureInPictureElement) {
        pipButton.disabled = true;
        await video.requestPictureInPicture();
      } else {
        await document.exitPictureInPicture();
      }
    } catch (error) {
      console.error(error)
    } finally {
      pipButton.disabled = false;
    }
  }

  // hideControls hides the video controls when not in use
  // if the video is paused, the controls must remain visible
  function hideControls() {
    if (video.paused) {
      return;
    }

    videoControls.classList.add('hide');
  }

  // showControls displays the video controls
  function showControls() {
    videoControls.classList.remove('hide');
  }

  function keyboardShortcuts(event) {

    if (videoContainer.classList.contains('videocontrols')) {
      const {
        key
      } = event;

      switch (key) {
        case 'k':
          togglePlay();
          if (video.paused) {
            showControls();
          } else {
            setTimeout(() => {
              hideControls();
            }, 2000);
          }
          break;
        case 'm':
          toggleMute();
          break;
      }
    }
  }

  // Add eventlisteners here
  playButton.addEventListener('click', togglePlay);
  video.addEventListener('play', updatePlayButton);
  video.addEventListener('pause', updatePlayButton);
  video.addEventListener('volumechange', updateVolumeIcon);
  video.addEventListener('click', togglePlay);
  volumeButton.addEventListener('click', toggleMute);
  video.addEventListener('mouseenter', showControls);
  video.addEventListener('mouseleave', hideControls);
  videoControls.addEventListener('mouseenter', showControls);
  videoControls.addEventListener('mouseleave', hideControls);
  document.addEventListener('keyup', keyboardShortcuts);

}

let players = document.querySelectorAll('.video-container');

if (players) {
  players.forEach(item => {
    hepburnPlayer(item);

  })
}